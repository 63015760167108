// src/pages/Pricing.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const Pricing = () => {
  const location = useLocation();
  const clientSecret = location.state?.clientSecret; // Get clientSecret from location state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientSecret) {
      amplitude.track('Pricing Page Loaded', {
        clientSecretProvided: true,
      });

      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      script.onload = () => {
        setLoading(false);
        amplitude.track('Pricing Table Loaded');
      };
      script.onerror = () => {
        console.error("Error loading Stripe pricing table script");
        amplitude.track('Pricing Table Load Failed', {
          error: "Script failed to load",
        });
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      console.error("Client secret is missing");
      amplitude.track('Pricing Page Loaded', {
        clientSecretProvided: false,
      });
    }
  }, [clientSecret]);

  if (loading) {
    return <div>Բեռնվում է...</div>;
  }

  return (
    <div style={containerStyle}>
      <h2>Որքա՞ն ժամանակ է Ձեզ հարկավոր</h2>
      {clientSecret ? (
        <stripe-pricing-table
          pricing-table-id="prctbl_1QPT8OGptgEY0Ls0e2Ehjz11"
          publishable-key="pk_live_51Pb6SOGptgEY0Ls0bwQadDAfUT68CdsmuJpXZA08rzcDCBVjIwGAhMknjzAIYRmQLuXTuB6rzB82NDa0eh2q5219000ifzDuIb"
          customer-session-client-secret={clientSecret}
          style={{ width: '100%' }} // Ensures the pricing table uses the full width on mobile
          onClick={() => amplitude.track('Pricing Option Clicked')}
        ></stripe-pricing-table>
      ) : (
        <div>Խնդրում ենք կրկին մուտք գործել</div>
      )}
    </div>
  );
};

// Responsive styling for the container
const containerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  '@media (max-width: 768px)': { // Tablet and mobile screens
    maxWidth: '100%',
    padding: '10px',
  },
  '@media (max-width: 480px)': { // Small mobile screens
    maxWidth: '100%',
    padding: '5px',
  },
};

export default Pricing;
